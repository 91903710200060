import React from "react";
import { StyledFooter } from "./style";
import mailSrc from "../../../static/2020/images/impactcall/mail.svg";
import logoSrc from "../../../static/2020/svg/logo.svg";

export const Footer = () => (
  <StyledFooter>
    <div>
      <h2>Contatti:</h2>
      <p>
        3Bee srl – Corso Di Porta Romana, 61
        <br />
        Milano 2022
        <br />
        www.3bee.com
        <br />
        <span>
          <img src={mailSrc} loading="lazy" />
          impactability@3Bee.com
        </span>
      </p>
    </div>
    <div>
      <div>
        <img src={logoSrc} loading="lazy" />
        <div>
          Call
          <br />
          for
          <br />
          Impactability
          <br />
          <span>MILANO - 2022</span>
        </div>
      </div>
      <div>
        “Se pensi di essere troppo piccolo per avere impatto, prova ad andare a
        letto con una zanzara che gira nella stanza.”
        <br />
        <span>Anita Roddick</span>
      </div>
      <nav>
        <a href="/">Politica sulla Privacy</a>
        <span></span>
        <a href="/">Politica sui cookie</a>
        <span></span>
        <a href="/">Avviso legale</a>
      </nav>
    </div>
  </StyledFooter>
);
